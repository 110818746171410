import Head from "next/head";
import TheMallOutletLayout from '../layouts/TheMallOutletLayout/TheMallOutletLayout'
function Error({ statusCode }) {
    const basicCss = `
      body {
        margin: 0;
        color:#ffffff;
        background:#1e1f23!important;
      }
      .error_wrapper {
        font-family:-apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Fira Sans', Avenir, 'Helvetica Neue', 'Lucida Grande', sans-serif;
        height:100vh;
        gap: 10vh;
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content: center;
      }
      .error_row {
        text-align:center;
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content:center
      }
      h1 {
        display:inline-block;
        border-right:1px solid rgba(255, 255, 255,.3);
        margin:0;
        margin-right:20px;
        padding:10px 23px 10px 0;
        font-size:24px;
        font-weight:500;
        vertical-align:top
      }
      .error_message {
        display:inline-block;
        text-align:left;
        line-height:49px;
        height:49px;
        vertical-align:middle
      }
      h2, p {
        font-size:14px;
        font-weight:normal;
        line-height:inherit;
        margin:0;
        padding:0
      }
      p {
        padding-top: 24px;
      }
      p a {
        text-decoration: underline;
      }
      p a:hover {
        text-decoration: none;
      }
      img {
        width: 204px;
      }
    `;
    return (
      <>
      <Head>  
        <style>{basicCss.replace(/\r?\n|\r/g, '')}</style>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta name="robots" content="noindex, nofollow" />
        <title>404 Error - Page Not Found | The Mall</title>
        <meta name="description" content='404 Error - Page Not Found' />
      </Head>
        <div className={"error_wrapper"}>
          <div className={"error_row"}>
            <img src="/assets/img/logo-themall.svg" alt="The Mall" />
          </div>
          <div className={"error_row"}>
            <h1>404</h1>
            <div className={"erorr_message"}>
              <h2>
                This page could not be found.
              </h2>
            </div>
          </div>
          <div className={"error_row"}>
            <p>
                <a href="/en/">
                    Return to EN Homepage
                </a>
            </p>
          </div>
        </div>
    </>
    )
  }
  
  Error.getInitialProps = ({ res, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404
    return { statusCode }
  }
  
  export default Error